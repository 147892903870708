export const VALIDATE_RECAPTCHA_URL = "/api/v1/validate_recaptcha";
export const EXEMPTION_URL = "/api/v1/save_user_exemption.json";
export const GET_TOWNS_BY_COUNTY_URL = "/api/v1/get_towns_by_county.json";
export const RECAPTCHA_KEY = "6LfXe74ZAAAAAHJBK2_g20veCbjMOmv54wNOLE_i";
export const SIGNUP_LINK_RECAPTCHA_PRIVACY =
  "https://policies.google.com/privacy";
export const SIGNUP_LINK_TOS_RECAPTCHA = "https://policies.google.com/terms";
export const LINK_WRITE_LETTERS_TO_EDITOR =
  "https://www.tapinto.net/articles/new?type=letters-to-the-editor";
export const LINK_WRITE_TO_COLUMN =
  "https://www.tapinto.net/articles/new?type=column";
export const EMAIL_CONTACT_TAPINTO = "contact@tapinto.net";
export const SUBSCRIBE_URL =
  "http://localhost:3002/migration_api/subscriptions/upsert_subscription";
export const ARTICLE_CREATION_URL = "/articles/create_diy";
export const FREELANCE_ARTICLE_CREATION_URL =
  "/articles/create_freelance_article_diy";
export const EVENT_CREATION_URL = "/events/create_diy";
export const MILESTONE_CREATION_URL = "/milestones/create_diy";
export const MILESTONE_PREVIEW_URL = "/milestones/preview";
export const BUSINESS_LISTING_CREATION_URL = "/directories/create_diy";
export const BUSINESS_LISTING_PREVIEW_URL = "/directories/preview";
export const CLASSIFIED_CREATION_URL = "/classifieds/create_diy";
export const REAL_ESTATE_CREATION_URL = "/real_estate_listings/create_diy";
export const REAL_ESTATE_PREVIEW_URL = "/real_estate_listings/preview";
export const OBITUARY_CREATION_URL = "/obituaries/create_diy";
export const GET_CATEGORIES_BY_TYPE_URL =
  "/api/v1/get_categories_by_content_type.json";
export const ARTICLE_PREVIEW_URL = "/articles/preview";
export const GOOGLE_ANALYTICS_ID = "UA-177340401-1";
export const SIGN_IN = "/users/sign_in";
export const SIGN_OUT = "/users/sign_out";
export const ADMIN = "/admin";
export const NEW_SUBSCRIPTION = "http://localhost:3006/subscriptions/new";
export const EDIT_USER_PROFILE = "http://localhost:3002/users/edit";
export const HOME = "http://localhost:3006/";
export const HOME_MONGO = "http://localhost:3002/";
export const ADVERTISE_WITH_US =
  "https://marketing.tapinto.net/advertisewithtapinto";
export const FRANCHISE_TAPINTO = "https://marketing.tapinto.net/start-tapinto";
export const ARTICLE_DRAFT_CREATION_URL = "/articles/save_draft";
export const CLASSIFIED_DRAFT_CREATION_URL = "/classifieds/save_draft";
export const MILESTONE_DRAFT_CREATION_URL = "/milestones/save_draft";
export const DIRECTORY_DRAFT_CREATION_URL = "/directories/save_draft";
export const REAL_ESTATE_DRAFT_CREATION_URL =
  "/real_estate_listings/save_draft";
export const EVENT_DRAFT_CREATION_URL = "/events/save_draft";
export const OBITUARY_DRAFT_CREATION_URL = "/obituaries/save_draft";
export const LEGAL_NOTICE_DRAFT_CREATION_URL = "/legal_notices/save_draft";
export const DELETE_DRAFT = "/api/v1/delete_draft";
export const LINK_TAPINTO = "https://www.tapinto.net";
export const EVENTS_DIY_FORM = "/events/new";
export const EVENTS_URL = "/events";
export const GOOGLE_MAPS_API_URL =
  "https://maps.googleapis.com/maps/api/js?v=3.exp&key=";
export const GOOGLE_GEOCODE_PREFIX =
  "https://maps.googleapis.com/maps/api/geocode/json?address=";
export const GOOGLE_MAPS_REDIRECT_URL_PREFIX = "https://maps.google.com/?q=";
export const MAPS_API_KEY_TAPINTO = "AIzaSyCfnL5OQrKJN_uQzjS0hnB7za4W4iSzym4";
export const ADD_EVENT = "https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js";
export const EVENT_PREVIEW_URL = "/events/preview";
export const CLASSIFIED_PREVIEW_URL = "/classifieds/preview";
export const GET_EVENTS = "/api/v1/get_events";
export const GET_CATEGORIES_FOR_EVENTS = "/api/v1/get_categories_for_events";
export const GET_UPCOMING_EVENTS = "/api/v1/get_upcoming_events";
export const SEARCH = "/search";
export const GAM_ID = "26641674";
export const GET_OBITUARIES = "/api/v1/get_obituaries";
export const GET_ALL_OBITUARIES_NAMES = "/api/v1/get_all_obituaries_names";
export const OBITUARIES = "/obituaries";
export const GET_OBITUARY_SERVICES = "/api/v1/get_obituary_services";
export const SUBMIT_OBITUARY_ROLE_REQUEST_URL =
  "/api/v1/request_obituary_contributor_role";
export const EDIT = "/edit";
export const UPDATE_REAL_ESTATE = "/real_estate_listings/:id";
export const UPDATE_EVENT = "/events/:id";
export const UPDATE_ARTICLE = "/articles/:id";
export const UPDATE_CLASSIFIED = "/classifieds/:id";
export const UPDATE_OBITUARY = "/obituaries/:id";
export const UPDATE_MILESTONE = "/milestones/:id";
export const UPDATE_DIRECTORY = "/directories/:id";
export const GET_HAMBURGER_MENU_ITEMS =
  "/api/v1/masterhead/hamburger_menu_items";
export const OBITUARY_PREVIEW_URL = "/obituaries/preview";
export const SAVE_MENU_URL = "/admin/towns/:id/save_menu";
export const TAPINTO_TERMS_OF_USE = "/pages/terms-of-use";
export const PRIVACY_POLICY = "/pages/privacy-policy";
export const RESET_PASSWORD_URL = "/api/v1/send_reset_password";
export const FIND_USER_URL = "/api/v1/user_exists";
export const UPGRADE_TO_CONTRIBUTOR_URL = "/api/v1/upgrade_to_contributor";
export const USER_SIGN_IN_URL = "/users/sign_in";
export const SEND_CONFIRMATION_EMAIL_URL =
  "/api/v1/send_confirmation_email_login";
export const CREATE_ACCOUNT_URL = "/api/v1/create_account";
export const TAPINTO_MARKETING_URL =
  "https://marketing.tapinto.net/advertisewithtapinto";
export const LOCKED_BY = "/api/v1/articles/:id/locked_by";
export const EMAIL_INFO_TAPINTO = "info@tapinto.net";
export const GET_USER_CONTRIBUTIONS = "/api/v1/get_user_contributions";
export const GET_USER_DRAFTS = "/api/v1/get_user_drafts";
export const CMS_UPDATE_ARTICLE_DRAFT = "/admin/article_methods/update_draft";
export const UPLOAD_FACEBOOK_PHOTO = "/api/v1/upload_facebook_photo";
export const REMOVE_PHOTO = "/api/v1/remove_photo";
export const URL_PIXLR = "http://pixlr.com/editor";
export const ENEWS_SIGNUP_IMAGE = "/enews_signup_image.png";
export const SAVE_FOOTER = "/admin/towns/:id/save_footer";
export const CREATE_FOOTER_LINK = "/admin/footer_links";
export const UPDATE_FOOTER_LINK = "/admin/footer_links/:id";
export const REMOVE_FOOTER_LINK = "/admin/footer_links/:id/remove_footer_link";
export const LOAD_FOOTER_LINKS_BY_TOWN =
  "/admin/footer_links/footer_links_by_town";
export const REAL_ESTATE_LISTING_DIY = "/real_estate_listings/new";
export const REAL_ESTATES_INDEX = "/real_estate_listings";
export const REAL_ESTATES_TOWN_INDEX = "/towns/:id/real_estate_listings";
export const GET_REAL_ESTATE_SINGLE = "/api/v1/get_real_estate";
export const GET_REAL_ESTATES = "/api/v1/get_real_estates";
export const SEND_REAL_ESTATES_CONTACT_EMAIL = "/api/v1/send_contact_email";
export const LOAD_TOWNS_WITH_CLUSTERS = "/api/v1/load_towns_with_clusters";
export const LOAD_TOWNS_WITHOUT_CLUSTERS =
  "/api/v1/load_towns_without_clusters";
export const FETCH_SUBSCRIPTIONS =
  "http://localhost:3002/migration_api/subscriptions/previous_user_subscriptions";
export const GET_TOP_LOCAL_NEWS_BY_TOWN = "/api/v1/towns/top_stories";
export const GET_OVERALL_TOP_LOCAL_NEWS = "/api/v1/overall/top_stories";
export const GET_TOP_LOCAL_NEWS_BY_SECTION_AND_TOWN =
  "/api/v1/sections/top_stories";
export const GET_DISPLAY_BLOCKS_BY_SECTION_AND_TOWN =
  "/api/v1/sections/display_blocks";
export const GET_DISPLAY_BLOCKS_BY_TOWN = "/api/v1/towns/display_blocks";
export const GET_SPONSORS_BY_TOWN = "/api/v1/towns/sponsors";
export const GET_VIDEO_AD = "/api/v1/towns/video_ad";
export const UPDATE_FOOTER_LINKS_ORDER =
  "/admin/towns/:id/update_footer_links_order";
export const FOOTER_BY_TOWN = "/api/v1/towns/:id/footer";
export const HALSTON_MEDIA_URL = "http://localhost:3002/";
export const ADVERTISING_POLICY = "/pages/advertising-policy";
export const FETCH_OVERALL_MASTHEAD_LINKS =
  "/api/v1/links_for_overall_masthead";
export const SITES_PAGE = "/tapinto_sites";
export const TAP_MOBILE_DEVICES_IMAGE =
  "https://tapinto-production.s3.amazonaws.com/assets/images/tap_mobile_devices_image.png";
export const FRANCHISE_YOUTUBE_VIDEO =
  "https://www.youtube.com/embed/56zSyxBQv8I";
export const OVERALL_FOOTER_LINKS = "/api/v1/overall/footer";
export const TAPINTO_SITES_IMAGE =
  "https://tapinto-production.s3.amazonaws.com/assets/images/tapinto_sites_image_background.png";
export const OVERALL_BACKGROUND =
  "https://tapinto-production.s3.amazonaws.com/assets/images/overall-background.png";
export const GET_TOWN_THEME = "/api/v1/towns/:id/theme";
export const LOAD_CUSTOM_MASTERHEAD_BUTTONS =
  "/api/v1/towns/:id/custom_masterhead_buttons";
export const LOAD_CUSTOM_MASTERHEAD_BUTTONS_WITH_NO_TOWN =
  "/api/v1/masterhead/load_buttons_from_theme";
export const SAVE_TOWN_THEME = "/admin/towns/:id/save_theme_data";
export const SAVE_TOWN_BUTTON = "/admin/towns/:id/save_town_button";
export const REMOVE_TOWN_BUTTON = "/admin/towns/:id/remove_town_button";
export const FETCH_TOWN_THEME = "/admin/towns/:id/fetch_town_theme";
export const RESTORE_BUTTONS_TO_DEFAULT =
  "/admin/towns/:id/restore_buttons_to_default";
export const RESTORE_THEME_TO_DEFAULT =
  "/admin/towns/:id/restore_theme_to_default";
export const FETCH_SIGN_UP_BOTTOM_TEXT =
  "/api/v1/towns/:id/sign_up_bottom_text";
export const MILESTONES_NEW_DIY = "/milestones/new";
export const MILESTONES_INDEX_API = "/api/v1/milestones";
export const MILESTONES_CATEGORY_INDEX_API =
  "/api/v1/milestones/categories/:categoryId";
export const MILESTONES_SHOW_API = "/api/v1/milestones/:milestoneId";
export const RESET_USER_PASSWORD = "/api/v1/users/passwords/update";
export const UPLOAD_DOCUMENTS = "/documents/upload";
export const HALSTON_MEDIA_HOST = "http://localhost:3002";
export const FETCH_DOCUMENTS = "/documents/documents_by_contribution";
export const SAVE_DOCUMENT_INFO = "/documents/save_info";
export const BULK_CAPTION_AND_CREDITS_UPDATE = "/documents/bulk_update";
export const REMOVE_DOCUMENT_INFO = "/documents/remove_document";
export const LOAD_DIRECTORIES_CATEGORIES =
  "/api/v1/directories/load_categories";
export const LOAD_DIRECTORIES_CITIES = "/api/v1/directories/load_cities";
export const LOAD_DIRECTORIES = "/api/v1/directories";
export const LOAD_SHOW_DIRECTORY = "/api/v1/directories/:id";
export const DIRECTORIES_NEW_DIY = "/directories/new";
export const DIRECTORIES_INDEX = "/directories";
export const DIRECTORIES_TOWN_INDEX = "/:id/directories";
export const SEND_DIRECTORIES_CONTACT_EMAIL =
  "/api/v1/directories/send_contact_email";
export const FETCH_EDITOR_INFO = "/api/v1/masterhead/editor_info";
export const GET_WEATHER_CONDITIONS = "/api/v1/weather/:town_slug";
export const GET_WEATHER_CONDITIONS_FOR_SECTION = "/api/v1/weather_for_section/:section_slug";
export const HALSTON_MEDIA_SIGN_IN = "/users/sign_in";
export const FETCH_SUBMIT_CONTENT_ITEMS =
  "/api/v1/masterhead/submit_content_items";
export const UPLOAD_EXIT_INTENT_PHOTO =
  "/api/v1/photos/upload_exit_intent_photo";
export const REMOVE_EXIT_INTENT_PHOTO =
  "/api/v1/photos/remove_exit_intent_photo";
export const FETCH_THEME = "/api/v1/towns/fetch_theme";
export const FETCH_THEME_TEXTS = "/api/v1/towns/fetch_theme_texts";
export const FETCH_LEGAL_NOTICE = "/api/v1/get_legal_notice";
export const GET_LEGAL_NOTICES = "/api/v1/get_legal_notices";
export const LEGAL_NOTICE_BACK_LINK = "/towns/:town_slug/legal_notices/";
export const REALTOR_INFO = "/api/v1/real_estate_listings/realtor_info";
export const FETCH_LEGAL_NOTICE_ENTITIES =
  "/api/v1/users/legal_notice_entities";
export const CREATE_LEGAL_NOTICE_ENTITY = "/api/v1/legal_notice_entities";
export const UPLOAD_LEGAL_NOTICE_ENTITY_LOGO =
  "/api/v1/legal_notice_entities/:id/upload_logo";
export const CREATE_LEGAL_NOTICES = "/legal_notices/create_diy";
export const PREVIEW_LEGAL_NOTICES = "/legal_notices/preview";
export const GET_COMPANY_CATEGORIES = "/api/v1/companies/categories";
export const GET_COMPANY_SUBCATEGORIES = "/api/v1/companies/subcategories";
export const GET_COMPANIES = "/api/v1/companies";
export const GET_COMPANY = "/api/v1/companies/:id";
export const CREATE_COMPANY = "/api/v1/companies";
export const UPDATE_COMPANY = "/api/v1/companies/:id";
export const DELETE_COMPANY = "/api/v1/companies/:id";
export const GET_SALE_FILES = "/api/v1/sale_files";
export const GET_SALE_FILE = "/api/v1/sale_files/:id";
export const GET_SPONSOR_DROPDOWN_OPTIONS = "/api/v1/sale_files/sponsor_dropdown_options";
export const UPDATE_SALE_FILE = "/api/v1/sale_files/:id";
export const DELETE_SALE_FILE = "/api/v1/sale_files/:id";
export const APPROVE_SALE_FILE = "/api/v1/sale_files/:id/approve";
export const COMPLETE_SALE_FILE = "/api/v1/sale_files/:id/complete";
export const REOPEN_SALE_FILE = "/api/v1/sale_files/:id/reopen";
export const DISAPPROVE_SALE_FILE = "/api/v1/sale_files/:id/disapprove";
export const SEND_EMAIL_PREVIEW = "/api/v1/sale_files/:id/send_preview_eblast";
export const SEND_TO_GAM = "/api/v1/sale_files/:id/send_to_gam";
export const CREATE_SALE_FILE = "/api/v1/sale_files";
export const GET_SALE_FILE_TYPES = "/api/v1/sale_files/sale_file_types";
export const GET_SALE_FILE_COMPANIES = "/api/v1/sale_files/sale_file_companies";
export const GET_BILLING_INFO_DROPDOWN_OPTIONS = "/api/v1/sale_files/sale_file_billing_information_dropdown_options";
export const GET_SALE_FILE_TYPE_OF_SIZES = "/api/v1/sale_files/sale_file_sizes";
export const GET_RESERVATIONS = "/api/v1/reservations";
export const CREATE_RESERVATION = "/api/v1/reservations";
export const CHECK_RESERVATION_AVAILABILITY = "/api/v1/reservations/check_availability";
export const UPDATE_RESERVATION = "/api/v1/reservations/:id";
export const DELETE_RESERVATION = "/api/v1/reservations/:id";
export const GENERATE_SALE_FILE = "/api/v1/reservations/:id/generate_sale_file";
export const GET_CREATIVE_SIZES = "/api/v1/reservations/get_creative_sizes";
export const UPLOAD_CMS_CONTENT_PHOTOS = "/api/v1/cms/upload_content_photos";
export const FETCH_CMS_CONTENT_PHOTOS = "/api/v1/cms/fetch_content_photos";
export const UPDATE_CMS_CONTENT_PHOTOS = "/api/v1/cms/update_content_photos";
export const DELETE_CMS_CONTENT_PHOTOS = "/api/v1/cms/delete_content_photos";
export const MASTHEAD_INFO = "/api/v1/masterhead/masthead_info";
export const NEARBY_TOWNS = "/api/v1/towns/load_nearby_towns";
export const GET_ALL_GAMES = "/api/v1/get_all_games";
export const GET_GAME_BY_SLUG = "/api/v1/get_game_by_slug/:slug";
export const SHOW_GAME_URL = "/towns/:town_slug/games/:game_slug";
export const INDEX_GAMES_URL = "/towns/:town_slug/games";
export const UPDATE_THEME_TEMPLATE =
  "/api/v1/theme_templates/:id/update_template";
export const FETCH_THEME_TEMPLATES =
  "/api/v1/theme_templates/fetch_theme_templates";
export const SEARCH_URL =
  "https://api-content-preview.tapinto.net/api/v1/search";
export const AUDIO_PLAYER_URL = "https://trinitymedia.ai/player/trinity/2900018397/?";