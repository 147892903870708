import axios from "axios";
axios.defaults.timeout = 2000000;

const get = async (url, params) => {
  const response = await axios.get(url, {
    headers: _getHeaders(),
    params,
  });

  if (response?.data?.code === 401) {
    redirectToLogin();
    return;
  }

  return response;
};

const get_serializer = async (url, params) => {
  const response = await axios.get(url, {
    headers: _getHeaders(),
    params,
    paramsSerializer: (params) => {
      const qs = require("qs");
      return qs.stringify(params, { arrayFormat: "brackets" });
    },
  });

  if (response?.data?.code === 401) {
    redirectToLogin();
    return;
  }

  return response;
};

const post = async (url, data, multipart) => {
  const response = await axios.post(url, data, {
    headers: _getHeaders(multipart),
  });

  if (response?.data?.code === 401) {
    redirectToLogin();
    return;
  }

  return response;
};

const serializeData = (data) => {
  const qs = require("qs");
  return qs.stringify(data, { arrayFormat: "brackets" });
};

const post_serializer = async (url, data, multipart) => {
  const response = await axios.post(url, serializeData(data), {
    headers: _getHeaders(multipart),
  });

  if (response?.data?.code === 401) {
    redirectToLogin();
    return;
  }

  return response;
};

const put_serializer = async (url, data, multipart) => {
  const response = await axios.put(url, serializeData(data), {
    headers: _getHeaders(multipart),
  });

  if (response?.data?.code === 401) {
    redirectToLogin();
    return;
  }

  return response;
}

const put = async (url, data, multipart) => {
  const response = await axios.put(url, data, {
    headers: _getHeaders(multipart),
  });

  if (response?.data?.code === 401) {
    redirectToLogin();
    return;
  }

  return response;
};

const patch = async (url, data, multipart) => {
  return await axios.patch(url, data, {
    headers: _getHeaders(multipart),
  });
};

const remove = async (url, multipart, data) => {
  return await axios.delete(url, {
    headers: _getHeaders(multipart),
    data,
  });
};

const _getHeaders = (multipart) => {
  // const token = document.querySelector("[name=csrf-token]").content; // FIXME: When token is required

  const headers = {
    "Content-Type": "application/json",
    Accept: "text/html, application/json",
  };

  if (multipart) {
    headers["Content-Type"] = "multipart/form-data";
  }

  return headers;
};

const redirectToLogin = () => {
  window.location.reload();
};

export default {
  get,
  get_serializer,
  post,
  post_serializer,
  patch,
  put,
  put_serializer,
  remove,
};
