const companyLabels = {
  NEW_COMPANY: 'New Company',
  NEW_SALE_FILE: 'Create new file',
  DELETE_CONFIRMATION_TITLE: 'Delete Company',
  DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this company?',
  COMPANY_INFORMATION_LABEL: 'Company Information',
  PAYMENT_INFORMATION_LABEL: 'Payment Information',
  COMPANY_FIELDS: {
    NAME: 'Company name',
    ADDRESS: 'Address',
    ADDRESS_2: 'Address 2',
    CITY: 'City',
    STATE: 'State',
    PHONE: 'Phone',
    ZIP_CODE: 'Zip',
    EMAIL: 'Email',
    PAYMENT_TYPE: 'Payment Type',
    CC_NAME: 'Name on CC',
    CARD_TYPE: 'Card Type',
    CC_NUMBER: 'CC Number',
    EXPIRATION_MONTH: 'Expiration month',
    EXPIRATION_YEAR: 'Expiration year',
    CC_ADDRESS_1: 'CC Address',
    CC_ADDRESS_2: 'CC Address 2',
  }
}

export default companyLabels