import React, { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  getDisplayBlocks,
  getSponsors,
  getVideoAd,
} from "../services/homePage";
import { fetchTheme, loadNearbyTowns } from "../services/townService";

const TownContext = React.createContext({});

const TownContextProvider = ({ children }) => {
  const [town, setTown] = useState();
  const [leftBlocks, setLeftBlocks] = useState([]);
  const [rightBlocks, setRightBlocks] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [videoAd, setVideoAd] = useState(null);
  const [townSlug, setTownSlug] = useState("");
  const [isHalstonMedia, setIsHalstonMedia] = useState(false);
  const [adTargets, setAdTargets] = useState();
  const [nearbyTowns, setNearbyTowns] = useState([]);
  const [themeTemplate, setThemeTemplate] = useState();
  const [topStoriesTemplate, setTopStoriesTemplate] = useState();

  useEffect(() => {
    const loadVideoAd = async () => {
      const response = await getVideoAd(town.id);
      if (response && response?.status === 200) {
        setVideoAd(response?.data?.video_ad_html);
      }
    };

    const buildAdTargets = () => {
      setAdTargets({ towns: [town?.slug_for_gam] });
    };

    const loadTheme = async () => {
      const response = await fetchTheme(town?.slug);

      if (response?.status === 200) {
        setThemeTemplate(response?.data?.theme_template);
      }
    };

    if (town) {
      loadVideoAd();
      buildAdTargets();
      loadTheme();
    }

    return () => {
      setVideoAd(null);
      setAdTargets();
      setThemeTemplate();
    };
  }, [town]);

  useEffect(() => {
    const loadDisplayBlocks = async () => {
      const response = await getDisplayBlocks(town.id);
      if (response && response?.status === 200) {
        const display_blocks = response?.data?.display_blocks;
        setLeftBlocks(display_blocks.left_blocks);
        setRightBlocks(display_blocks.right_blocks);
      }
    };

    const loadSponsors = async () => {
      const response = await getSponsors(town.id);
      if (response && response?.status === 200) {
        setSponsors(response?.data?.sponsors);
      }
    };

    if (town) {
      loadDisplayBlocks();
      loadSponsors();
    }

    return () => {
      setLeftBlocks([]);
      setRightBlocks([]);
      setSponsors([]);
    };
  }, [town]);

  useEffect(() => {
    const fetchNearbyTowns = async () => {
      const response = await loadNearbyTowns(townSlug);
      if (response?.status === 200) {
        setNearbyTowns(response?.data?.nearby_towns);
      }
    };

    if (townSlug === "halston-media") setIsHalstonMedia(true);
    if (townSlug) fetchNearbyTowns();

    return () => {
      setIsHalstonMedia(false), setNearbyTowns([]);
    };
  }, [townSlug]);

  useEffect(() => {
    if (themeTemplate) {
      setTopStoriesTemplate(themeTemplate.top_stories_template);
    }
  }, [themeTemplate]);

  useEffect(() => {
    const loadTheme = async () => {
      const response = await fetchTheme(town?.slug);

      if (response?.status === 200) {
        setThemeTemplate(response?.data?.theme_template);
      }
    };

    if (town) {
      loadTheme();
    }

    return () => {
      setThemeTemplate();
    };
  }, [town]);

  const townMutation = useCallback((town) => {
    setTown(town);
  }, []);

  return (
    <TownContext.Provider
      value={{
        setTown,
        leftBlocks,
        rightBlocks,
        sponsors,
        videoAd,
        town,
        townSlug,
        setTownSlug,
        isHalstonMedia,
        adTargets,
        nearbyTowns,
        themeTemplate,
        topStoriesTemplate,
        townMutation,
      }}
    >
      {children}
    </TownContext.Provider>
  );
};

TownContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useTown = () => {
  const TownContextValues = useContext(TownContext);

  if (!TownContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the TownContextProvider"
    );
  }

  return TownContextValues;
};

const withTownContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <TownContextProvider>
      <Component {...props} />
    </TownContextProvider>
  );

  return ComponentWithProvider;
};

export { useTown, withTownContextProvider };
