import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: `${theme.spacing(1)}px !important`,
    border: "none !important",
    boxShadow: "none !important",
  },
}));

export { useStyles };
