import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "./Spinner.style";
import Typography from "../Typography/Typography";
import labels from "../../config/labels";

const DEFAULT_LABELS = labels.DEFAULT_LOADING_LABELS;

const Spinner = ({
  className,
  spinnerColor,
  withLoadingOverlay,
  loadingOverlayClassName,
  withFadingLabels,
  fadingLabelsOptions,
  noContainer,
}) => {
  const classes = useStyles(spinnerColor);

  const FadingLabels = () => {
    const labelsToUse = fadingLabelsOptions?.labels || DEFAULT_LABELS;
    const durationMs = fadingLabelsOptions?.durationMs || 4000;
    const typographyProps = {
      level: fadingLabelsOptions?.color || "t5_text_5",
      color: fadingLabelsOptions?.level || "white",
      bold: fadingLabelsOptions?.bold || true,
    };
    const [fadeProp, setFadeProp] = useState({
      fade: classes.fade_in_label,
      label: labelsToUse[0],
    });

    useEffect(() => {
      const timeout = setInterval(() => {
        if (fadeProp.fade === classes.fade_in_label) {
          setFadeProp({
            ...fadeProp,
            fade: classes.fade_out_label,
          });
        } else {
          setFadeProp({
            fade: classes.fade_in_label,
            label: labelsToUse[Math.floor(Math.random() * labelsToUse.length)],
          });
        }
      }, durationMs);

      return () => clearInterval(timeout);
    }, [fadeProp]);

    return (
      <div
        className={clsx(
          fadingLabelsOptions?.classes?.container &&
            fadingLabelsOptions?.classes?.container,
          classes.label_container,
          fadeProp.fade
        )}
      >
        <Typography
          color={typographyProps.color}
          level={typographyProps.level}
          bold={typographyProps.bold}
          {...(fadingLabelsOptions?.classes?.typography && {
            className: fadingLabelsOptions?.classes?.typography,
          })}
        >
          {fadeProp.label}
        </Typography>
      </div>
    );
  };

  return withLoadingOverlay ? (
    <div
      className={clsx(
        classes.loading_overlay,
        loadingOverlayClassName && loadingOverlayClassName
      )}
    >
      <div className={classes.container}>
        <CircularProgress
          className={clsx(classes.spinner, className && className)}
        />
        {withFadingLabels && fadingLabelsOptions?.labels?.length > 0 && (
          <FadingLabels />
        )}
      </div>
    </div>
  ) : (
    noContainer ? (
      <CircularProgress className={className}/>
    ) : (
      <div className={classes.container}>
        <CircularProgress
          className={clsx(classes.spinner, className && className)}
        />
        {withFadingLabels && fadingLabelsOptions?.labels?.length > 0 && (
          <FadingLabels />
        )}
      </div>
    )
  );
};

Spinner.propTypes = {
  noContainer: PropTypes.bool,
  className: PropTypes.string,
  spinnerColor: PropTypes.string,
  withLoadingOverlay: PropTypes.bool,
  loadingOverlayClassName: PropTypes.string,
  withFadingLabels: PropTypes.bool,
  fadingLabelsOptions: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    durationMs: PropTypes.number,
    classes: PropTypes.shape({
      container: PropTypes.string,
      typography: PropTypes.string,
    }),
    typographyProps: PropTypes.shape({
      level: PropTypes.string,
      color: PropTypes.string,
      cold: PropTypes.bool,
    }),
  }),
};

Spinner.defaultProps = {
  withLoadingOverlay: false,
  spinnerColor: "#7fc035",
  withFadingLabels: false,
  noContainer: false,
  fadingLabelsOptions: {
    labels: DEFAULT_LABELS,
    durationMs: 4000,
    classes: {
      container: "",
      typography: "",
    },
    typographyProps: {
      color: "white",
      level: "t5_text_5",
      bold: true,
    },
  },
};

export default Spinner;
