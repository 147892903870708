import { Box as MuiBox } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const Box = ({ children, className, boxShadow, ...rest }) => {
  return (
    <MuiBox className={className} boxShadow={boxShadow} {...rest}>
      {children}
    </MuiBox>
  );
};

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  boxShadow: PropTypes.number,
};

export default Box;
