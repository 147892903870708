import React from "react";
import PropTypes from "prop-types";

const Edit = ({ className, fillColor, width, height, style, onClick }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <path
        d="M0.959305 8.9407V11.0407H3.0593L9.2529 4.8471L7.1529 2.7471L0.959305 8.9407ZM10.8769 3.2231C11.0953 3.0047 11.0953 2.6519 10.8769 2.4335L9.56651 1.1231C9.34811 0.904705 8.9953 0.904705 8.7769 1.1231L7.7521 2.1479L9.8521 4.2479L10.8769 3.2231Z"
        fill={fillColor}
        fillOpacity="1"
      />
    </svg>
  );
};

Edit.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

Edit.defaultProps = {
  fillColor: "white",
  width: 12,
  height: 12,
};

export default Edit;
